import { useState, ChangeEvent, FormEvent } from 'react'
import { CheckBoxes, FormField, StyledForm } from './styles'
import { Button } from '../Header/styles'
import { motion } from 'framer-motion'
import {
  blurOut,
  initialDown,
  initialVisible,
  showUp,
} from '../../helpers/animationConstants'
import { useMouseContext } from '../../context/mouseContext'

const encode = (data: Record<string, any>) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [requests, setRequests] = useState([
    { label: 'Development', value: 'Web Development', checked: false },
    { label: 'Design', value: 'Web Design', checked: false },
    { label: 'Branding', value: 'Brand & Marketing', checked: false },
    { label: 'Ecommerce', value: 'Ecommerce', checked: false },
    { label: '3D', value: '3D Development', checked: false },
    {
      label: 'Mobile',
      value: 'Mobile App Development',
      checked: false,
    },
  ])

  const { cursorChangeHandler } = useMouseContext()

  const [formState, setFormState] = useState({
    name: '',
    position: '',
    email: '',
    company: '',
    message: '',
    requirements: [] as string[],
    budget: 5,
  })

  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, name: e.target.value })
  }

  const handlePosition = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, position: e.target.value })
  }

  const handleCompany = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, company: e.target.value })
  }

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, email: e.target.value })
  }

  const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({ ...formState, message: e.target.value })
  }

  const handleBudget = (e: ChangeEvent<HTMLInputElement>) => {
    const budgetValue = parseFloat(e.target.value)
    setFormState({ ...formState, budget: budgetValue })
  }

  const handleCheckBox = (index: number, isChecked: boolean, value: string) => {
    let currentReq = requests[index]
    currentReq.checked = !isChecked
    let reqClone = [...requests]
    reqClone[index] = currentReq

    const updatedRequirements = formState.requirements.includes(value)
      ? formState.requirements.filter((item) => item !== value)
      : [...formState.requirements, value]

    setFormState({ ...formState, requirements: updatedRequirements })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contactForm', ...formState }),
    })
      .then(() => setIsSuccess(true))
      .catch((error) => console.log(error))
    e.preventDefault()
  }

  return (
    <StyledForm
      className="contactForm"
      onSubmit={handleSubmit}
      autoComplete="off"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      initial={{ height: 'auto' }}
      animate={isSuccess ? { height: '30%' } : { height: 'auto' }}
    >
      <motion.div
        className="container"
        initial={initialVisible}
        animate={isSuccess ? blurOut : initialVisible}
      >
        <FormField>
          <input
            onMouseEnter={() => cursorChangeHandler('interactive', 'fill')}
            onMouseLeave={() => cursorChangeHandler('default', '')}
            type="text"
            name="name"
            value={formState.name}
            placeholder="Your name"
            autoComplete="off"
            onChange={handleName}
            required
          />
          <input
            onMouseEnter={() => cursorChangeHandler('interactive', 'fill')}
            onMouseLeave={() => cursorChangeHandler('default', '')}
            type="text"
            name="position"
            value={formState.position}
            placeholder="Your position/role"
            autoComplete="off"
            onChange={handlePosition}
          />
        </FormField>

        <input type="hidden" name="Contact Form" value="Contact submission" />

        <FormField>
          <input
            onMouseEnter={() => cursorChangeHandler('interactive', 'fill')}
            onMouseLeave={() => cursorChangeHandler('default', '')}
            type="text"
            name="company"
            value={formState.company}
            placeholder="Company name"
            autoComplete="off"
            onChange={handleCompany}
          />
          <input
            onMouseEnter={() => cursorChangeHandler('interactive', 'fill')}
            onMouseLeave={() => cursorChangeHandler('default', '')}
            type="email"
            name="email"
            autoComplete="off"
            value={formState.email}
            required
            placeholder="Your email"
            onChange={handleEmail}
          />
        </FormField>

        <FormField
          className="budget"
          onMouseEnter={() => cursorChangeHandler('interactive', 'drag')}
          onMouseLeave={() => cursorChangeHandler('default', '')}
        >
          <p className="form-label">Your indicative budget</p>
          <input
            type="range"
            name="budget"
            min="5"
            autoComplete="off"
            step="2.5"
            value={formState.budget}
            onChange={handleBudget}
            max="100"
          />
          <output>
            {formState.budget}k{formState.budget > 97.5 ? '+' : ''}
          </output>
        </FormField>

        <FormField>
          <p className="form-label check">You are looking for...</p>
          <CheckBoxes>
            {requests.map(({ value, label, checked }, index: number) => {
              return (
                <div
                  key={index}
                  className="checkbox"
                  onMouseEnter={() =>
                    cursorChangeHandler('interactive', 'click')
                  }
                  onMouseLeave={() => cursorChangeHandler('default', '')}
                >
                  <input
                    type="checkbox"
                    name="requirements[]"
                    autoComplete="off"
                    checked={checked}
                    value={value}
                    onChange={() => handleCheckBox(index, checked, value)}
                  />
                  <label
                    htmlFor={label}
                    className={checked ? 'checked' : 'unchecked'}
                    onClick={() => handleCheckBox(index, checked, value)}
                  >
                    {label}
                  </label>
                </div>
              )
            })}
          </CheckBoxes>
        </FormField>

        <FormField
          onMouseEnter={() => cursorChangeHandler('interactive', 'fill')}
          onMouseLeave={() => cursorChangeHandler('default', '')}
        >
          <textarea
            name="message"
            placeholder="I would like to request A++ services for..."
            value={formState.message}
            onChange={handleMessage}
          ></textarea>
        </FormField>

        <Button
          type="submit"
          onMouseEnter={() => cursorChangeHandler('interactive', 'submit')}
          onMouseLeave={() => cursorChangeHandler('default', '')}
        >
          Submit
        </Button>
      </motion.div>
      <motion.div
        className="success"
        initial={initialDown}
        animate={isSuccess ? showUp : initialDown}
      >
        <h2>Thank you</h2>
        <p>You can expect an email from us soon.</p>
      </motion.div>
    </StyledForm>
  )
}

export default Form
