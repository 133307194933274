/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, cubicBezier } from 'framer-motion'
import type { TypedObject } from '@portabletext/types'
import { ContentSticky, ContentWrapper } from './styles'
import { IArticle, IImage, IProject } from '../../types'
import { Route, Routes, useLocation } from 'react-router-dom'
import About from '../About/About'
import Work from '../Work/Work'
import Contact from '../Contact/Contact'
import Home from '../Home/Home'
import { useEffect } from 'react'
import Blog from '../Blog/Blog'
import Article from '../Article/Article'

interface IContentProps {
  email: string
  homeLine: string
  homeContent: TypedObject
  aboutTitle: string
  aboutContent: TypedObject
  approachContent: TypedObject
  servicesTitle: string
  servicesContent: string[]
  techLine: string
  technologies: string[]
  contactContent: TypedObject
  projects: IProject[]
  articles: IArticle[]
  homeImage: IImage
  contactImage?: IImage
  aboutImage?: IImage
}

const Content = ({
  email,
  homeLine,
  homeContent,
  aboutTitle,
  aboutContent,
  approachContent,
  servicesTitle,
  servicesContent,
  techLine,
  technologies,
  contactContent,
  projects,
  articles,
  homeImage,
  contactImage,
  aboutImage,
}: IContentProps) => {
  const location = useLocation()
  const bezier = cubicBezier(0.98, 0, 0.41, 0.97)
  const width = window.innerWidth
  const isSmall = width < 1000
  const isMobile = width < 500

  const variants = {
    visible: {
      y: 0,
      transition: {
        type: bezier,
        duration: 0.6,
        delay: 0.3,
      },
    },
    invisible: {
      y: '100%',
      transition: {
        type: bezier,
        duration: 0.4,
        delay: 0,
      },
    },
  }

  const scrollToTop = () => {
    const height = window.innerHeight
    if (
      (location.pathname.includes('/work') ||
        location.pathname.includes('/about') ||
        location.pathname.includes('/contact') ||
        location.pathname.includes('/articles')) &&
      !isMobile
    ) {
      window.scrollTo({
        top: height - (isSmall && !isMobile ? 64 : 116),
        behavior: 'smooth',
      })
    } else if (
      (location.pathname === '/work' ||
        location.pathname === '/about' ||
        location.pathname === '/contact' ||
        location.pathname === '/articles') &&
      isMobile
    ) {
      window.scrollTo({
        top: height - 56,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [location.pathname])

  return (
    <ContentWrapper variants={variants}>
      {/* <ContentSticky id="container"> */}
      <AnimatePresence mode='wait' initial={false}>
        <Routes location={location} key={location.pathname.split('/')[1]}>
          <Route
            key="home"
            path="/"
            element={
              <About
                title={aboutTitle}
                content={aboutContent}
                servicesLine={servicesTitle}
                approach={approachContent}
                services={servicesContent}
                techLine={techLine}
                technologies={technologies}
                secondTitle={homeLine}
                secondContent={homeContent}
              />
            }
          />
          <Route
            key="articles"
            path="/articles"
            element={<Blog articles={articles} />}
          >
            <Route
              key={location.pathname}
              path="/articles/:slug"
              element={<Article />}
            />
          </Route>
          <Route
            key="work"
            path="/work"
            element={<Work projects={projects} />}
          />
          <Route
            key="contact"
            path="/contact"
            element={
              <Contact
                title={'Contact'}
                email={email}
                content={contactContent}
              />
            }
          />
        </Routes>
      </AnimatePresence>
      {/* </ContentSticky> */}
    </ContentWrapper>
  )
}

export default Content
