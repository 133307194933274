import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ContentWrapper = styled(motion.section)`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 180px);
`

export const ContentSticky = styled.div`
  position: relative;
  width: calc(100% - 64px);
  margin: 4px 32px 0 32px;
  border-radius: 8px;
  border-top: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .top-bar {
    position: sticky;
    top: 126px;
    min-height: calc(100vh - 178px);
    margin-bottom: calc(-100vh + 178px);
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.text};
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    z-index: 4;
    pointer-events: none;
  }

  @media screen and (max-width: 1000px) {
    width: calc(100% - 32px);
    margin: 0 16px;

    .top-bar {
      top: 77px;
      min-height: calc(100vh - 116px);
      margin-bottom: calc(-100vh + 116px);
    }
  }

  @media screen and (max-width: 500px) {
    width: calc(100% - 24px);
    margin: 0 12px;
    border-radius: 6px;

    .top-bar {
      top: 54px;
      min-height: 200px;
      height: 100%;
      margin-bottom: -200px;
      border-radius: 6px 6px 0 0;
    }
  }
`
