/* eslint-disable react/jsx-no-comment-textnodes */
import type { TypedObject } from '@portabletext/types'
import { PortableText } from '@portabletext/react'
import { ContentContainer } from './styles'
import { motion } from 'framer-motion'

interface IAboutProps {
  title: string
  secondTitle: string
  servicesLine: string
  techLine: string
  approach: TypedObject
  content: TypedObject
  secondContent: TypedObject
  services: string[]
  technologies: string[]
}

const About = ({
  title,
  secondTitle,
  content,
  secondContent,
  servicesLine,
  approach,
  services,
  techLine,
  technologies,
}: IAboutProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, filter: 'blur(20px)' }}
      animate={{
        opacity: 1,
        filter: 'blur(0)',
        transition: { duration: 0.6 },
      }}
      exit={{
        opacity: 0,
        filter: 'blur(20px)',
        transition: { duration: 0.5 },
      }}
    >
      <ContentContainer
        id="about"
        initial={{ opacity: 0, filter: 'blur(20px)' }}
        animate={{
          opacity: 1,
          filter: 'blur(0)',
          transition: { duration: 0.6 },
        }}
        exit={{
          opacity: 0,
          filter: 'blur(20px)',
          transition: { duration: 0.5 },
        }}
      >
        <div className="text-flex">
          <h1 className="text-title">{title}</h1>

          <div className="text text-paragraphs">
            <PortableText value={content} />
            <PortableText value={approach} />
            <PortableText value={secondContent} />
            <div className="services-wrapper">
              <div className="column">
                <div className="header">
                  <h2>{servicesLine}</h2>
                </div>
                <div className="services">
                  <ul>
                    {services.map((service, index) => (
                      <li key={index}>{service}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="header">
                  <h2>{techLine}</h2>
                </div>
                <div className="services">
                  <ul>
                    {technologies.map((tech, index) => (
                      <li key={index}>{tech}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </motion.div>
  )
}

export default About
