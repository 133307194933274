import type { TypedObject } from '@portabletext/types'
import { ContentContainer } from '../About/styles'
import { PortableText } from '@portabletext/react'
import { useState } from 'react'
import { Button } from '../Header/styles'
import { useMouseContext } from '../../context/mouseContext'
import { AnimatePresence, motion } from 'framer-motion'
import Form from '../Form/Form'
import { Helmet } from 'react-helmet-async'

interface IContactProps {
  title: string
  email: string
  content: TypedObject
}

const Contact = ({ title, email, content }: IContactProps) => {
  const [isEmailCopied, setIsEmailCopied] = useState(false)
  const { cursorChangeHandler } = useMouseContext()
  const width = window.innerWidth
  const isMobile = width < 500

  const handleClick = () => {
    navigator.clipboard.writeText(email)
    setIsEmailCopied(true)
    setTimeout(() => {
      setIsEmailCopied(false)
    }, 2000)
  }

  return (
    <motion.div
      initial={{ opacity: 0, filter: 'blur(10px)' }}
      animate={{
        opacity: 1,
        filter: 'blur(0)',
        transition: { duration: 0.55 },
      }}
      exit={{ opacity: 0, filter: 'blur(10px)', transition: { duration: 0.4 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>A++ - Contact us</title>
        <meta
          name="title"
          content="A++ - Contact us"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Get in touch with us and get a quote for a project. We operate worldwide, but based between Berlin, Amsterdam and Milan"
          data-react-helmet="true"
        />
      </Helmet>
      <ContentContainer>
        <div className="text-flex">
          <h1 className="text-title">{title}</h1>
          <div className="text text-paragraphs contact-text">
            <Form />
            <PortableText value={content} />
            <div className="response">
              <Button
                className="email"
                onClick={handleClick}
                onMouseEnter={() => cursorChangeHandler('interactive', 'copy')}
                onMouseLeave={() => cursorChangeHandler('default', '')}
              >
                <h2>{email}</h2>
                <AnimatePresence>
                  {isEmailCopied && (
                    <motion.span
                      className="email-copied"
                      initial={{
                        opacity: 0,
                        y: -50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.3, type: 'spring' },
                      }}
                      exit={{
                        opacity: 0,
                        y: -50,
                        transition: { duration: 0.3, type: 'spring' },
                      }}
                    >
                      copied
                    </motion.span>
                  )}
                </AnimatePresence>
              </Button>
            </div>
          </div>
        </div>
      </ContentContainer>
    </motion.div>
  )
}

export default Contact
