import { motion } from 'framer-motion'
import styled from 'styled-components'

export const CustomCursor = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.text};
  border-radius: 50%;
  pointer-events: none;
  background-color: ${(props) => props.theme.colors.text};
  transform: translate(-20px, -20px);
  transition: background 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  z-index: 999;

  &.cursordefault {
    background-color: ${(props) => props.theme.colors.text};
    border: 1px solid ${(props) => props.theme.colors.background};
    /* transition: all 0.2s ease; */
  }

  p {
    margin: 0;
    padding: 0;
    font-family: Euclid Regular;
    font-size: 8px;
  }

  &.cursorinteractive {
    /* mix-blend-mode: 'difference'; */
    transform: translate(-20px, -20px);
    background-color: ${(props) => props.theme.colors.fadedBackground};
    border: 1px solid ${(props) => props.theme.colors.text};

    &.expanded {
      width: 100px;
      height: 40px;
      border-radius: 8px;
      transform: translate(-50px, -20px);
      padding: 8px;
    }

    &::after {
      display: none;
    }
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`
