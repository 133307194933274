import { SetStateAction, useEffect, useState } from 'react'
import client from './client'
import { IArticle, IData, IProject } from './types'
import { GlobalStyles } from './styles'
import Intro from './components/Intro/Intro'
import Header from './components/Header/Header'
import { GlobalFonts } from './fonts/fonts'
import Cursor from './components/Cursor/Cursor'
import Content from './components/Content/Content'
import { AnimatePresence } from 'framer-motion'
import Footer from './components/Footer/Footer'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { themeDef, themeRed } from './helpers/theme'
import { Helmet, HelmetProvider } from 'react-helmet-async'

function App() {
  const [data, setData] = useState<IData>()
  const [projects, setProjects] = useState<IProject[]>([])
  const [articles, setArticles] = useState<IArticle[]>([])
  const [isNavVisible, setIsNavVisible] = useState(false)
  const [selectedTheme, setSelectedTheme] = useState(themeDef)

  useEffect(() => {
    async function fetchData() {
      const result = await client.fetch(`*[_type == "home"][0]`)
      const projectData = await client.fetch(
        `*[_type=='project'] {
          ...
      } | order(publicationDate desc)`
      )
      const articleData = await client.fetch(
        `*[_type=='article'] {
          ...
      } | order(publicationDate desc)`
      )
      setData(result)
      setProjects(projectData)
      setArticles(articleData)
    }

    fetchData()
  }, [])

  useEffect(() => {
    const currentTheme = JSON.parse(
      localStorage.getItem('current-theme') as string
    )
    if (currentTheme) {
      setSelectedTheme(currentTheme)
    }
  }, [])

  return (
    <div className="App">
      <HelmetProvider>
        <ThemeProvider theme={selectedTheme}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>A++ Creative Engineering</title>
            <meta
              name="title"
              content="A++ Creative Engineering"
              data-react-helmet="true"
            />
            <meta
              name="description"
              content="A++ is a Digital Product Studio specializing in elegant UI experiences across devices. We take care of concept, strategy, design, development and partner with cutting edge studios to deliver impeccable results"
              data-react-helmet="true"
            />
          </Helmet>
          <main>
            <GlobalStyles />
            <GlobalFonts />
            <Cursor />

            <AnimatePresence>
              {data && isNavVisible && <Header />}
            </AnimatePresence>
            <Intro
              isNavVisible={isNavVisible}
              setIsNavVisible={setIsNavVisible}
              setSelectedTheme={setSelectedTheme}
              selectedTheme={selectedTheme}
            />
            {data && (
              <Content
                aboutTitle={data.aboutLine}
                aboutContent={data.about}
                approachContent={data.approach}
                servicesTitle={data.servicesLine}
                servicesContent={data.services}
                projects={projects}
                email={data.email}
                homeLine={data.homeLine}
                homeContent={data.homeContent}
                contactContent={data.contactContent}
                techLine={data.techLine}
                technologies={data.technologies}
                homeImage={data.homeImage}
                articles={articles}
              />
            )}
            <Footer />
          </main>
        </ThemeProvider>
      </HelmetProvider>
    </div>
  )
}

export default App
