import React, { useEffect, useRef, useState } from 'react'
import { Coordinates, IImage } from '../../types'
import { Gallery, InnerLine, ShadowGallery, ShadowImage } from './styles'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { AnimatePresence, motion } from 'framer-motion'
import ImageHandler from '../ImageHandler'
import { useMouseContext } from '../../context/mouseContext'
import { Button } from '../Header/styles'
import { Link } from 'react-router-dom'
import { bezier } from '../../helpers/animationConstants'
import { useTheme } from 'styled-components'

interface SliderProps {
  images: IImage[]
  title: string
  projectUrl: string
  sliderNumber: number
}

const GallerySlider = ({
  images,
  title,
  projectUrl,
  sliderNumber,
}: SliderProps) => {
  const theme = useTheme()
  const [currentSlide, setCurrentSlide] = useState<number | null>(0)
  const [expandedImage, setExpandedImage] = useState<IImage | string>('')
  const [expanded, setExpanded] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const imageRef = useRef<HTMLDivElement>(null)
  const [isFirstImage, setIsFirstImage] = useState(true)
  const [isInitial, setIsInitial] = useState(true)

  const isMobile = window.innerWidth < 900
  const height = window.innerHeight / 2
  const slideWidth = isMobile
    ? window.innerWidth / 1.2
    : window.innerWidth / 2.8

  const [animationPosition, setAnimationPosition] = useState<Coordinates>({
    leftPos: 0,
    topPos: 0,
    width: 0,
    sliderWidth: 0,
  })

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    breakpoints: {
      '(min-width: 500px)': {
        slides: { perView: 3, spacing: 4 },
      },
      '(min-width: 1000px)': {
        slides: { perView: 4, spacing: 4 },
      },
    },
    loop: false,
    slides: {
      perView: 2,
      spacing: 4,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel as number)
    },
  })
  const [sliderRefTwo, instanceRefTwo] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 'auto',
      spacing: 4,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel as number)
    },
  })
  const { cursorChangeHandler } = useMouseContext()

  useEffect(() => {
    isFirstImage &&
      instanceRefTwo.current &&
      instanceRefTwo.current?.moveToIdx(currentSlide as number, false, {
        duration: 0,
      })
  }, [currentSlide, images, isVisible])

  const blowUp = {
    zIndex: [99, 99, 99, 99, 99],
    width: [
      animationPosition.width,
      animationPosition.width,
      slideWidth,
      slideWidth,
      slideWidth,
    ],
    opacity: [0, 1, 1, 1, 1, 0],
    top: [
      animationPosition.topPos,
      animationPosition.topPos,
      height,
      height,
      height,
    ],
    y: ['0%', '0%', '-50%', '-50%', '-50%'],
    left: [
      animationPosition.leftPos,
      animationPosition.leftPos,
      isMobile ? 32 : 56,
      isMobile ? 32 : 56,
      isMobile ? 32 : 56,
    ],
    transition: {
      duration: 1.6,
      ease: bezier,
    },
  }

  const shrink = {
    opacity: [1, 1, 1, 1, 1],
    zIndex: [99, -1, -1, -1, -1],
    width: [
      slideWidth,
      animationPosition.width,
      animationPosition.width,
      animationPosition.width,
      animationPosition.width,
    ],
    top: [
      height,
      animationPosition.topPos,
      animationPosition.topPos,
      animationPosition.topPos,
      animationPosition.topPos,
    ],
    left: [
      isMobile ? 32 : 56,
      animationPosition.leftPos,
      animationPosition.leftPos,
      animationPosition.leftPos,
      animationPosition.leftPos,
    ],
    y: ['-50%', '0%', '0%', '0%', '0%'],
    transition: {
      duration: 1.3,
      ease: bezier,
    },
  }

  const invisible = {
    opacity: 0,
    zIndex: -1,
  }

  const show = {
    opacity: [0, 0, 0, 1, 1],
    zIndex: [-1, -1, -1, -1, 999],
    transition: {
      duration: 1.7,
      delay: 0.3,
    },
  }

  const hide = {
    zIndex: [999, 999, 999, 999, -1],
    opacity: [1, 0, 0, 0, 0],
    transition: {
      duration: 1,
    },
  }

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('blocked')
    } else {
      setTimeout(() => {
        document.body.classList.remove('blocked')
      }, 300)
    }
  }, [isVisible])

  const handleShow = (index: number, image: IImage, position: Coordinates) => {
    setExpanded(true)
    setIsVisible(true)
    setCurrentSlide(index)
    setIsInitial(false)
    setExpandedImage(image)
    setAnimationPosition(position)
    setTimeout(() => {
      setIsFirstImage(false)
      setExpandedImage(images[0])
    }, 1500)
  }

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => {
    // Set the position to match the current slide when closing
    instanceRef.current?.moveToIdx(0)
    instanceRefTwo.current?.moveToIdx(0)
    setCurrentSlide(0)

    setTimeout(() => {
      setTimeout(() => {
        setIsFirstImage(true)
      }, 500)
      const currentSliderElement = document
        .querySelectorAll('.original-slider')
        [sliderNumber].querySelectorAll('.slide')[0]
      const slideRect = currentSliderElement.getBoundingClientRect()

      const position = {
        leftPos: slideRect.left,
        topPos: slideRect.top,
        width: slideRect.width,
      }
      setAnimationPosition(position as Coordinates)
      setExpanded(false)
      setIsVisible(false)
    }, 500)
  }

  const isLastSlide = isMobile
    ? currentSlide === images.length - 1
    : currentSlide === images.length - 2

  return (
    <InnerLine>
      <div className="button-row">
        <Button
          onMouseEnter={() => cursorChangeHandler('interactive', '')}
          onMouseLeave={() => cursorChangeHandler('default', '')}
        >
          <p>LIVE</p>
          <div className="blink" />
          <Link to={projectUrl} target="_blank"></Link>
        </Button>
      </div>

      <Gallery
        ref={sliderRef}
        className={
          isVisible
            ? 'keen-slider original-slider hidden'
            : 'keen-slider original-slider'
        }
        onMouseEnter={() => cursorChangeHandler('interactive', 'drag')}
        onMouseLeave={() => cursorChangeHandler('default', '')}
      >
        {images &&
          images.map((image: IImage, index: number) => (
            <motion.div
              className="keen-slider__slide slide"
              key={index}
              style={{ minWidth: 200, maxWidth: 200 }}
              onClick={(event) => {
                const clickedImage = event.target as HTMLImageElement
                const slideWidth = clickedImage.offsetWidth // You can adjust this width
                const imageRect = clickedImage.getBoundingClientRect()
                const position = {
                  leftPos: imageRect.left,
                  topPos: imageRect.top,
                  width: slideWidth,
                }
                handleShow(index, image, position as Coordinates)
              }}
              onMouseEnter={() => cursorChangeHandler('interactive', 'expand')}
              onMouseLeave={() => cursorChangeHandler('default', '')}
            >
              <ImageHandler
                imgSrc={image}
                altText={`${title}-image-${index}`}
                width={2000}
              />
            </motion.div>
          ))}
      </Gallery>
      <ShadowGallery
        className={isVisible ? 'visible' : 'hidden'}
        animate={
          isVisible
            ? {
                backgroundColor: theme.colors.fadedBackground,
                transition: {
                  duration: 1.8,
                  type: 'linear',
                },
              }
            : {
                backgroundColor: 'rgba(0,0,0,0)',
                transition: {
                  duration: 0.2,
                  type: 'linear',
                },
              }
        }
      >
        <motion.div
          className={expanded ? 'slider-frame visible' : 'slider-frame'}
          initial={{ opacity: 0 }}
          animate={
            expanded
              ? {
                  opacity: 1,
                  transition: {
                    opacity: { duration: 0.5 },
                    borderColor: { duration: 0.5 },
                  },
                }
              : {
                  opacity: 0,
                  transition: { duration: 0.8 },
                }
          }
        >
          <AnimatePresence>
            {expanded && (
              <>
                <motion.div
                  className="button-container"
                  initial={invisible}
                  animate={show}
                  exit={hide}
                  onMouseEnter={() => cursorChangeHandler('interactive', '')}
                  onMouseLeave={() => cursorChangeHandler('default', '')}
                >
                  <Button
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleClose(event)}
                  >
                    <p>CLOSE</p>
                  </Button>
                </motion.div>
                <motion.div
                  className="border"
                  initial={invisible}
                  animate={show}
                  exit={hide}
                />
              </>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {expandedImage !== '' && (
              <ShadowImage
                className="shadowImage"
                ref={imageRef}
                style={{
                  position: 'fixed',
                  top: animationPosition.topPos,
                  left: animationPosition.leftPos,
                  width: animationPosition.width,
                }}
                animate={expanded ? blowUp : isInitial ? invisible : shrink}
              >
                <ImageHandler
                  noPlaceholder={true}
                  imgSrc={expandedImage as IImage}
                  width={2000}
                  altText={`${title}-image-shadow`}
                />
                <motion.div className="frame" />
              </ShadowImage>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {isVisible && (
              <motion.div
                className={
                  isVisible
                    ? 'slider-visible keen-slider'
                    : 'slider-hidden keen-slider'
                }
                ref={sliderRefTwo}
                initial={invisible}
                animate={show}
                exit={hide}
                onMouseEnter={() => cursorChangeHandler('interactive', 'drag')}
                onMouseLeave={() => cursorChangeHandler('default', '')}
              >
                {images &&
                  images.map((image: IImage, index: number) => (
                    <motion.div
                      className="keen-slider__slide"
                      key={index}
                      style={{ minWidth: slideWidth, maxWidth: slideWidth }}
                    >
                      <ImageHandler
                        imgSrc={image}
                        altText={`${title}-image-${index}`}
                        width={2000}
                      />
                    </motion.div>
                  ))}
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            className={expanded ? 'backdrop expanded' : 'backdrop collapsed'}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleClose(event)
            }
          />
          <AnimatePresence>
            {expanded && (
              <>
                <motion.div
                  className="button-row bottom-left"
                  initial={invisible}
                  animate={show}
                  exit={hide}
                  onMouseEnter={() => cursorChangeHandler('interactive', '')}
                  onMouseLeave={() => cursorChangeHandler('default', '')}
                >
                  <Button
                    onClick={() => instanceRefTwo.current?.prev()}
                    disabled={currentSlide === 0}
                    className={currentSlide === 0 ? 'disabled' : ''}
                  >
                    <p>PREV</p>
                  </Button>
                  <Button
                    onClick={() => instanceRefTwo.current?.next()}
                    disabled={isLastSlide}
                    className={isLastSlide ? 'disabled' : ''}
                  >
                    <p>NEXT</p>
                  </Button>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </motion.div>
      </ShadowGallery>
    </InnerLine>
  )
}

export default GallerySlider
