import { cubicBezier } from 'framer-motion'

export const bezier = cubicBezier(1, 0.13, 0.47, 0.71)

export const bezierTwo = cubicBezier(0.6, 0.01, -0.05, 0.95)

export const cubicEaseTwo = cubicBezier(1, -0.04, 0.97, 0.98)

export const initialVisible = {
  filter: 'blur(0px)',
  opacity: 1,
}

export const blurOut = {
  filter: 'blur(20px)',
  opacity: 0.6,

  transition: {
    duration: 0.5,
    ease: bezierTwo,
  },
}

export const initialDown = {
  y: '100%',
}

export const showUp = {
  y: '0%',
  transition: {
    duration: 0.5,
    delay: 0.2,
    ease: bezierTwo,
  },
}
