import styled from 'styled-components'
import { motion } from 'framer-motion'

export const HeaderBar = styled(motion.nav)`
  position: fixed;
  top: 0;
  z-index: 5;
  width: calc(100% - 64px);
  border-radius: 8px;
  margin: 32px;
  height: auto;
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  align-content: end;
  transition: filter 0.3s ease;
  /* grid-gap: 8px; */

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.background};
  }

  .cell {
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: background 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    button {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      a {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 112%;
        height: 140%;
        &.disabled {
          pointer-events: none;
        }
        &.enabled {
          pointer-events: auto;
        }
      }
    }
    background-color: ${(props) => props.theme.colors.fadedBackground};
    border: 1px solid ${(props) => props.theme.colors.text};
    border-radius: 8px;
    padding: 16px;
    margin-right: 8px;
    &:first-child {
      background-color: ${(props) => props.theme.colors.text};
      svg {
        width: 150px;
        height: auto;
      }

      button {
        display: flex;
        top: 0;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.text};
      h2 {
        color: ${(props) => props.theme.colors.background};
      }
    }

    &.selected {
      background-color: ${(props) => props.theme.colors.text};
      h2 {
        color: ${(props) => props.theme.colors.background};
      }
    }
  }
  h1,
  h2 {
    font-family: Euclid Regular;
    color: ${(props) => props.theme.colors.text};
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
  }

  @media only screen and (max-width: 1000px) {
    width: calc(100% - 32px);
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 0;
    border-radius: 0;
    margin: 16px;

    .cell {
      margin: 0;
      button {
        width: 100%;
        height: 100%;
        position: relative;
        top: unset;
        left: unset;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          top: -5px;
          left: -5px;
        }
      }
      &:first-child {
        width: 120px;
        background-color: ${(props) => props.theme.colors.text};
      }
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.colors.fadedBackground};
      border: 1px solid ${(props) => props.theme.colors.text};
      padding: 8px;
      border-radius: 4px;

      h2 {
        color: ${(props) => props.theme.colors.text};
      }
    }
  }
  @media only screen and (max-width: 500px) {
    width: calc(100% - 24px);
    margin: 12px;
    gap: 4px;
    .cell {
      &:first-child {
        width: 80px;
      }
      width: 100%;
      background-color: ${(props) => props.theme.colors.fadedBackground};
      border: 1px solid ${(props) => props.theme.colors.text};
      padding: 6px;
      border-radius: 3px;

      h2 {
        font-size: 11px;
      }
    }
  }
`
export const Button = styled(motion.button)`
  background: transparent;
  outline: none;
  border: none;
  color: ${(props) => props.theme.colors.text};
  appearance: none;
  padding: 0;
`
