import client from '../client'
import imageUrlBuilder from '@sanity/image-url'
import { IImage } from '../types'
import { useState, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useTheme } from 'styled-components'
import { bezierTwo } from '../helpers/animationConstants'

interface ImgHandlerProps {
  imgSrc: IImage
  altText: string
  width: number
  noPlaceholder?: boolean
}
const ImageHandler = ({
  imgSrc,
  altText,
  width,
  noPlaceholder,
}: ImgHandlerProps) => {
  const builder = imageUrlBuilder(client)
  const theme = useTheme()

  const [isLoaded, setIsLoaded] = useState(false)
  const containerRef = useRef(null)

  const handleLoad = () => {
    setIsLoaded(true)
  }

  function urlFor(source: IImage) {
    return builder.image(source)
  }
  return (
    <div
      ref={containerRef}
      className="image-container"
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <picture>
        <source srcSet={urlFor(imgSrc).format('webp').width(width).url()} />
        <source srcSet={urlFor(imgSrc).format('jpg').width(width).url()} />
        <img
          src={urlFor(imgSrc).width(width).url()}
          alt={altText}
          onLoad={handleLoad}
          width={width}
          height='auto'
        />
        <AnimatePresence>
          {!isLoaded && !noPlaceholder && (
            <motion.div
              initial={{ opacity: 1, y: 0 }}
              animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
              exit={{
                opacity: 0,
                filter: 'blur(20px)',
                transition: { duration: 0.7, ease: bezierTwo },
              }}
              style={{
                position: 'relative',
                zIndex: 3,
                width: '100%',
                height: '100%',
                backgroundColor: theme.colors.text,
                top: 0,
                left: 0,
              }}
              viewport={{ once: true, amount: 0.6 }}
            />
          )}
        </AnimatePresence>
      </picture>
    </div>
  )
}

export default ImageHandler
