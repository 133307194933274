import { motion } from 'framer-motion'
import { ContentContainer } from '../About/styles'
import { Outlet, useLocation } from 'react-router-dom'
import { IArticle } from '../../types'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

interface IBlogProps {
  articles: IArticle[]
}

const Blog: React.FC<IBlogProps> = ({ articles }) => {
  const [isArticle, setIsArticle] = useState(false)
  const location = useLocation()
  const isArticlesPage = location.pathname === '/articles'

  useEffect(() => {
    if (isArticlesPage) {
      setIsArticle(false)
    }
  }, [location.pathname])

  const exitVariant = {
    opacity: 0,
    filter: 'blur(10px)',
    transition: { duration: 0.65 },
  }

  const animateVariant = {
    opacity: 1,
    filter: 'blur(0)',
    transition: { duration: 0.55 },
  }

  const articleVariant = {
    opacity: 1,
    filter: 'blur(10px)',
    transition: { duration: 0.55 },
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>A++ - Writing, Case Studies, Blog, Tutorials</title>
        <meta
          name="title"
          content="A++ - Writing, Case Studies, Blog, Tutorials"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="At A++ we like to write down our experiences. Be it a case study, an opinion piece or a tutorial, there is always something insightful to share"
          data-react-helmet="true"
        />
      </Helmet>
      <motion.div
        initial={{
          opacity: 0,
          filter: 'blur(10px)',
        }}
        animate={isArticle ? articleVariant : animateVariant}
        exit={exitVariant} // Apply exit animation only if it's not a nested route
      >
        <ContentContainer id="about">
          <div className="text-flex">
            <h1>Coming soon</h1>
            <div className="text text-paragraphs">
              <p>
                Currently in the process of writing cutting-edge web science
                that will be featured here. Stay tuned.
              </p>
            </div>
          </div>
          {/* <div>
            <ul>
              {articles.map((item: IArticle, index: number) => (
                <li key={index} onClick={() => setIsArticle(true)}>
                  <Link to={`/articles/${item.slug.current}`}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div> */}
        </ContentContainer>
      </motion.div>
      <Outlet />
    </>
  )
}

export default Blog
