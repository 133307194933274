import ImageHandler from '../ImageHandler'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atelierSulphurpoolDark, atelierSeasideDark, atelierSavannaDark, shadesOfPurple } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { coldarkCold, dark, vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const RichContent = {
  types: {
    image: ({ value }: any) => {
      return (
        <ImageHandler imgSrc={value} altText={value.toString()} width={2000} />
      )
    },
    code: ({ value }: any) => {
      return (
        <SyntaxHighlighter language="javascript" style={shadesOfPurple}>{value.code}</SyntaxHighlighter>
      )
    },
  },
}