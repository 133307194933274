import { createGlobalStyle } from 'styled-components'

// export const backgroundColor = 'rgba(66, 63, 212, 1)'

// // export const backgroundColor = 'rgba(235, 71, 66, 1)'
// export const fadedBackground = 'rgba(66, 63, 212, 0.8)'
// // export const fadedBackground = 'rgba(235, 71, 66, 0.8)'

// export const accentColor = '#0083C5'

// export const secondColor = '#FFC434'

// export const thirdColor = '#6CCAB4'

// export const textColor = '#cdcdcd'

// export const fadedWhite = 'rgba(255, 255, 255, 0.5)'

// export const secondBgColor = '#f5f5f5'

export const GlobalStyles = createGlobalStyle`

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  cursor: none;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


html {
  /* background-color: white; */
  height: auto;
  background: ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.background};
}

*::selection {
  background: ${(props) => props.theme.colors.text};
  color: ${(props) => props.theme.colors.background};
}

/* Set core body defaults */
body {
  width: 100%;
  scroll-behavior: smooth;
  background: ${(props) => props.theme.colors.background};
  background-color:${(props) => props.theme.colors.background};
  line-height: 1.3;
  font-family: Euclid Light;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    
  &.blocked {
    overflow: hidden;

    .header {
      filter: blur(5px);
    }
  }

  h1 {
    font-family: Synthes Neue;
    font-size: 52px;
    line-height: 1.1;
    color: ${(props) => props.theme.colors.text};
    padding-bottom: 0.25em;
    text-transform: uppercase;
  }

  .top-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 146px;
    background-color: ${(props) => props.theme.colors.background};
    z-index: 3;
  }

  main {
    position: relative;
    height: auto;
    padding-top: 100vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    /* overflow: hidden; */
  }
}

.image-container {
  picture {
    display: grid;
    overflow: hidden;

    img, div {
      grid-area: 1/1;
    }
  }
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    cursor: none;
    * {
        color: ${(props) => props.theme.colors.text};
    }
  }

  p {
    margin-bottom: 16px;
    line-height: 1.4;
    font-size: 16px;
  }

  @media only screen and (max-width: 1150px) {

    p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1000px) {
    *,
    *::before,
    *::after {
      box-sizing: border-box;
      cursor: auto !important;
    }

    cursor: auto !important;
    
    body {
        .top-screen {
        height: 90px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    body {
        .top-screen {
        height: 64px;
      }
    }
 
  }

  @media only screen and (max-width: 800px) {

    p {
    font-size: 12px;
    }
  }
`
