import useMousePosition from '../../hooks/useMousePosition'
import { CustomCursor } from './styles'
import { useMouseContext } from '../../context/mouseContext'

const Cursor = () => {
  const { xPos, yPos } = useMousePosition()
  const { cursorVariant, cursorLabel } = useMouseContext()

  return (
    <CustomCursor
      className={'cursor' + cursorVariant}
      style={{ left: `${xPos}px`, top: `${yPos}px` }}
    >
      <p>{cursorLabel}</p>
    </CustomCursor>
  )
}

export default Cursor
