import styled from 'styled-components'

export const FooterContainer = styled.div`
  width: 100%;
  height: 32px;

  @media screen and (max-width: 1000px) {
    height: 16px;
  }

  @media screen and (max-width: 500px) {
    height: 12px;
  }
`
