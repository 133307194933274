import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Button } from '../Header/styles'

export const StyledForm = styled(motion.form)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 40px;
  border: 1px solid ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.fadedBackground};
  border-radius: 8px;
  overflow: hidden;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .success {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;

    h2 {
      font-size: 40px;
    }
  }

  h2 {
    font-family: Synthes Neue;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  output {
    display: flex;
    justify-content: center;
    min-width: 64px;
    font-family: Synthes Neue;
    font-size: 18px;
    padding-top: 27px;
    color: ${(props) => props.theme.colors.text};
  }

  input[type='string'],
  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    height: 100%;
    color: ${(props) => props.theme.colors.text};
    padding-bottom: 8px;
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.text};
    background: transparent;
    font-size: 16px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.text};
      opacity: 0.6;
      font-size: 14px;
    }
  }

  input[type='range'] {
    height: 34px;
    appearance: none;
    background: transparent;
    -webkit-appearance: none;
    margin: 24px 0 0px;
    width: 100%;

    &:focus {
      outline: none;

      &::-webkit-slider-runnable-track {
        background: ${(props) => props.theme.colors.background};
      }

      &::-ms-fill-lower {
        background: ${(props) => props.theme.colors.background};
      }

      &::-ms-fill-upper {
        background: ${(props) => props.theme.colors.background};
      }
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 12px;
      background: ${(props) => props.theme.colors.background};
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.colors.text};
    }

    &::-webkit-slider-thumb {
      border: 1px solid ${(props) => props.theme.colors.text};
      height: 23px;
      width: 23px;
      border-radius: 23px;
      background: ${(props) => props.theme.colors.background};
      appearance: none;
      -webkit-appearance: none;
      margin-top: -7px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 12px;
      background: ${(props) => props.theme.colors.background};
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.colors.text};
    }

    &::-moz-range-thumb {
      border: 1px solid ${(props) => props.theme.colors.text};
      height: 23px;
      width: 23px;
      border-radius: 23px;
      background: ${(props) => props.theme.colors.background};
    }

    &::-ms-track {
      width: 100%;
      height: 12px;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: ${(props) => props.theme.colors.background};
      border: 1px solid ${(props) => props.theme.colors.text};
      border-radius: 8px;
    }

    &::-ms-fill-upper {
      background: ${(props) => props.theme.colors.background};
      border: 1px solid ${(props) => props.theme.colors.text};
      border-radius: 8px;
    }

    &::-ms-thumb {
      margin-top: 1px;
      border: 1px solid ${(props) => props.theme.colors.text};
      height: 23px;
      width: 23px;
      border-radius: 23px;
      background: ${(props) => props.theme.colors.background};
    }
  }

  ${Button} {
    width: 100%;
    background-color: ${(props) => props.theme.colors.text};
    color: ${(props) => props.theme.colors.background};
    padding: 24px 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
  }

  @media screen and (max-width: 600px) {
    input[type='string'],
    input[type='text'],
    input[type='email'],
    textarea {
      margin-top: 16px;
    }

    input[type='range'] {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 1000px) {
    border-radius: 3px;

    ${Button} {
      border-radius: 3px;
    }
  }

  @media screen and (min-width: 1500px) {
    input[type='string'],
    input[type='text'],
    input[type='email'],
    textarea {
      padding-bottom: 16px;
    }

    input[type='range'] {
      margin-top: 32px;
    }

    input,
    p,
    textarea {
      font-size: 18px !important;

      &::-webkit-input-placeholder {
        font-size: 18px !important;
      }
    }
  }
`

export const FormField = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 16px 0;
  gap: 12px;

  p.form-label {
    position: absolute;
    top: 0;
    color: ${(props) => props.theme.colors.text};
    opacity: 0.6;
    font-size: 14px;
    white-space: nowrap;
    margin: 0;
    margin-bottom: 0;
  }

  &.budget {
    flex-direction: row;
    align-items: flex-start;
    margin: 40px 0;

  }

  textarea {
    margin-top: 8px;
    min-height: 120px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;


    output {
        padding-top: 24px;
        font-size: 16px;
    }
  }

  @media screen and (min-width: 1500px) {
    margin: 32px 0 18px;
  }

`

export const CheckBoxes = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-gap: 6px;
  margin-top: 36px;

  div.checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 16px;
    border: 1px solid ${(props) => props.theme.colors.text};
    border-radius: 8px;
    overflow: hidden;

    input {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: ${(props) => props.theme.colors.text};

      &:checked {
        background-color: ${(props) => props.theme.colors.text};
      }
    }

    label {
      position: relative;
      z-index: 2;
      color: ${(props) => props.theme.colors.text};

      &.checked {
        color: ${(props) => props.theme.colors.background};
      }
    }
  }

  @media screen and (max-width: 1000px) {
    div.checkbox {
      border-radius: 3px;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1500px) {
    margin-top: 44px;
  }
`
