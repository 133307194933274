import { DefaultTheme } from 'styled-components'

export const themeDef: DefaultTheme = {
  colors: {
    background: 'rgba(66, 63, 212, 1)',
    fadedBackground: 'rgba(66, 63, 212, 0.8)',
    text: '#cdcdcd',
    fadedText: 'rgba(205, 205, 250, 0.7)',
  },
  borderRadius: '8px',
}

export const themeRed: DefaultTheme = {
  colors: {
    background: 'rgba(235, 71, 66, 1)',
    fadedBackground: 'rgba(235, 71, 66, 0.7)',
    text: '#1e1e1e',
    fadedText: 'rgba(30, 30, 30, 0.7)',
  },
  borderRadius: '8px',
}
