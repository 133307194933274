import { AnimatePresence, cubicBezier, motion, useScroll } from 'framer-motion'
import { IntroWrapper } from './styles'
import { useEffect, useState } from 'react'
import { useMouseContext } from '../../context/mouseContext'
import { Button } from '../Header/styles'
import { DefaultTheme, useTheme } from 'styled-components'
import { themeDef, themeRed } from '../../helpers/theme'

interface ClickCoordinates {
  first: number
  second: number
}

interface MouseCoordinates {
  x: number
  y: number
}

interface IIntroProps {
  isNavVisible: boolean
  setIsNavVisible: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>
  selectedTheme: DefaultTheme
}

const cityTexts = ['BER', 'AMS', 'MIL']

const Intro = ({
  isNavVisible,
  setIsNavVisible,
  setSelectedTheme,
  selectedTheme,
}: IIntroProps) => {
  const theme = useTheme()
  const count = [1, 2, 3, 4, 5, 6, 7, 8]
  const [scrollPosition, setScrollPosition] = useState(0)
  const [blurAmount, setBlurAmount] = useState(0)
  const [opacityAmount, setOpacityAmount] = useState(0)
  const [isInteractive, setIsInteractive] = useState(true)
  const [currentTime, setCurrentTime] = useState<string>('')
  const cityOneText = 'BER'
  const cityTwoText = 'AMS'
  const cityThreeText = 'MIL'
  const [cityIndex, setCityIndex] = useState(0)

  const currentPosition = window.scrollY
  const isSmall = window.innerWidth < 1000
  const isMobile = window.innerWidth < 500
  const [clicked, setClicked] = useState<ClickCoordinates>({
    first: 0,
    second: 0,
  })
  const [mousePosition, setMousePosition] = useState<MouseCoordinates>({
    x: 0,
    y: 0,
  })
  const { cursorChangeHandler } = useMouseContext()

  const handleClick = (e: React.MouseEvent<SVGPathElement>) => {
    e.currentTarget.id === 'first'
      ? setClicked({ first: clicked.first + 180, second: clicked.second })
      : setClicked({ first: clicked.first, second: clicked.second + 180 })
  }

  const bezier = cubicBezier(0.98, 0, 0.41, 0.97)

  const rotatedFirst = {
    rotate: clicked.first,
  }

  const rotatedSecond = {
    rotate: clicked.second,
  }

  const getCoordinates = (e: MouseEvent) => {
    const width = window.innerWidth
    const height = window.innerHeight
    const position = {
      x: (width / 2 - e.pageX) / 75,
      y: (height / 2 - e.pageY) / 55,
    }

    return position
  }

  const variants = {
    blurred: {
      filter: `blur(${20}px)`,
      transition: {
        duration: 0.5,
        type: bezier,
      },
    },
    visible: {
      filter: `blur(${blurAmount}px)`,
      transition: {
        duration: 0,
        type: bezier,
      },
    },
    transparent: {
      opacity: 0,
    },
    opaque: {
      opacity: opacityAmount,
      transition: {
        duration: 0,
        type: 'linear',
      },
    },
  }

  const handleMouseMove = (e: MouseEvent) => {
    setMousePosition(getCoordinates(e))
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY
      if (currentPosition >= 100) {
        setIsNavVisible(true)
      } else {
        setIsNavVisible(false)
      }
      setScrollPosition(currentPosition)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    // Calculate the blur amount based on the scroll position divided by the coefficient
    const coefficient = 15 // Adjust this value to control the blur intensity
    const opacityCoefficient = 0.0015
    const newBlurAmount = scrollPosition / coefficient
    const newOpacityAmount = scrollPosition * opacityCoefficient
    if (scrollPosition > 0 && scrollPosition < 500) {
      setBlurAmount(newBlurAmount)
      setOpacityAmount(newOpacityAmount)
      setIsInteractive(false)
    }
  }, [scrollPosition])

  useEffect(() => {
    if (currentPosition > 400) {
      setBlurAmount(30)
      setOpacityAmount(0.6)
      setIsInteractive(false)
    }

    if (currentPosition === 0) {
      setBlurAmount(0)
      setOpacityAmount(0)
      setIsInteractive(true)
    }
  }, [currentPosition])

  const date = new Date()

  const scrollDown = () => {
    window.scrollBy({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleThemeChange = () => {
    if (selectedTheme === themeRed) {
      setSelectedTheme(themeDef)
      localStorage.setItem('current-theme', JSON.stringify(themeDef))
    } else {
      setSelectedTheme(themeRed)
      localStorage.setItem('current-theme', JSON.stringify(themeRed))
    }
  }

  useEffect(() => {
    // Function to update the time
    function updateTime() {
      const date = new Date()
      const formattedTime = date.toLocaleTimeString(navigator.language, {
        timeZone: 'Europe/Brussels',
        hour: '2-digit',
        minute: '2-digit',
      })
      setCurrentTime(formattedTime)
      setCityIndex((prevIndex) => (prevIndex + 1) % 3)
    }

    // Call updateTime immediately to display the time when the component mounts
    updateTime()

    // Set up a setInterval to update the time every minute
    const intervalId = setInterval(updateTime, 5000)

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [])

  const sentenceAlt = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1,
        opacity: { duration: 0.1 },
        staggerChildren: 0.15,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 1,
        opacity: { duration: 0.5, delay: 1 },
        staggerChildren: 0.15,
      },
    },
  }

  const letterAniAlt = {
    initial: {
      y: 40,
    },
    animate: {
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      y: -40,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <IntroWrapper
      onMouseMove={(e) => handleMouseMove(e.nativeEvent)}
      onMouseEnter={() => cursorChangeHandler('interactive', '')}
      onMouseLeave={() => cursorChangeHandler('default', '')}
      initial={variants.visible}
      variants={variants}
      animate={variants.visible}
      className={
        isInteractive
          ? 'interactive intro-section'
          : 'non-interactive intro-section'
      }
    >
      <div className="info">
        <h1> Creative Engineering </h1>
        <Button onClick={scrollDown}>
          {/* <p>scroll down or click here</p> */}
        </Button>

       

        <div className="date-display">
          <div className="city-display">
            {cityTexts.map((city, index) => {
              return (
                <AnimatePresence key={city + '-' + index}>
                  {cityIndex === index && (
                    <motion.h2
                      variants={sentenceAlt}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      {city.split('').map((char, charIndex) => (
                        <motion.span
                          key={char + '-' + charIndex}
                          variants={letterAniAlt}
                        >
                          {char}
                        </motion.span>
                      ))}
                    </motion.h2>
                  )}
                </AnimatePresence>
              )
            })}
          </div>
        </div>
      </div>

      {count.map((el: number, index: number) => (
        <motion.svg
          key={index}
          initial={{ x: 0, y: 0, opacity: 0 }}
          animate={{
            x: isMobile ? 0 : -mousePosition.x * index,
            y: isMobile ? 0 : -mousePosition.y * index,
            zIndex: -index,
            opacity: 1 - index * 0.15,
            transition: {
              type: 'spring',
              stiffness: 50,
              restDelta: 0.2,
              restSpeed: 0.2,
              bounce: 0,
              mass: 0.5,
            },
          }}
          width="100%"
          height="100%"
          viewBox="0 0 1414 500"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            onClick={handleThemeChange}
            onMouseEnter={() =>
              cursorChangeHandler('interactive expanded', 'change theme')
            }
            onMouseLeave={() => cursorChangeHandler('interactive', '')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M505.89 499.527C500.376 499.527 495.083 497.359 491.154 493.49L399.38 403.106C398.856 402.59 398.425 401.988 397.976 401.405C397.767 401.134 397.538 400.874 397.29 400.625L239.304 242.639C239.152 242.487 238.996 242.343 238.836 242.206L104.628 103.982C99.6231 98.828 90.8881 102.371 90.8881 109.555V273.188C90.8881 277.607 94.4698 281.188 98.8881 281.188H209.375H218.082C220.204 281.188 222.239 282.031 223.739 283.532L282.89 342.683C287.93 347.723 284.36 356.34 277.233 356.34H98.8881C94.4698 356.34 90.8881 359.921 90.8881 364.34V491.527C90.8881 495.946 87.3064 499.527 82.8881 499.527H8C3.58172 499.527 0 495.946 0 491.527V12.3418C0 5.71437 5.37259 0.341789 12 0.341789H111.822C117.113 0.341789 122.188 2.43823 125.937 6.17213L607.495 485.859C612.55 490.895 608.984 499.527 601.849 499.527H505.89Z"
            fill={theme.colors.text}
          />
          <path
            d="M600.493 280.777C604.911 280.777 608.493 284.359 608.493 288.777L608.493 347.032C608.493 351.451 604.911 355.032 600.493 355.032L541.486 355.032C536.518 355.032 531.748 353.087 528.197 349.612L471.875 294.495C466.752 289.481 470.302 280.777 477.471 280.777L600.493 280.777Z"
            fill={theme.colors.text}
          />
          <motion.path
            id="second"
            onClick={(e) => handleClick(e)}
            onMouseEnter={() =>
              cursorChangeHandler('interactive expanded', 'spin the plus')
            }
            onMouseLeave={() => cursorChangeHandler('interactive', '')}
            initial={{ rotate: 0 }}
            animate={rotatedSecond}
            transition={{ duration: 1, delay: index * 0.02, ease: bezier }}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1199.3 138.348C1194.88 138.348 1191.3 141.929 1191.3 146.348L1191.3 282.476L1055.17 282.476C1050.75 282.476 1047.17 286.057 1047.17 290.476L1047.17 347.768C1047.17 352.187 1050.75 355.768 1055.17 355.768L1191.3 355.768L1191.3 491.892C1191.3 496.311 1194.88 499.892 1199.3 499.892L1256.59 499.892C1261.01 499.892 1264.59 496.311 1264.59 491.892L1264.59 355.768L1400.71 355.768C1405.13 355.768 1408.71 352.187 1408.71 347.768V290.476C1408.71 286.057 1405.13 282.476 1400.71 282.476L1264.59 282.476L1264.59 146.348C1264.59 141.929 1261.01 138.348 1256.59 138.348L1199.3 138.348Z"
            fill={theme.colors.text}
          />
          <motion.path
            id="first"
            onClick={(e) => handleClick(e)}
            onMouseEnter={() =>
              cursorChangeHandler('interactive expanded', 'spin the plus')
            }
            onMouseLeave={() => cursorChangeHandler('interactive', '')}
            initial={{ rotate: 0 }}
            animate={rotatedFirst}
            transition={{ duration: 1, delay: index * 0.02, ease: bezier }}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M799.131 138.348C794.712 138.348 791.131 141.929 791.131 146.348L791.131 282.476L655 282.476C650.582 282.476 647 286.057 647 290.476L647 347.768C647 352.187 650.582 355.768 655 355.768L791.131 355.768L791.131 491.892C791.131 496.311 794.712 499.892 799.131 499.892L856.423 499.892C860.842 499.892 864.423 496.311 864.423 491.892L864.423 355.768L1000.54 355.768C1004.96 355.768 1008.54 352.187 1008.54 347.768V290.476C1008.54 286.057 1004.96 282.476 1000.54 282.476L864.423 282.476L864.423 146.348C864.423 141.929 860.842 138.348 856.423 138.348L799.131 138.348Z"
            fill={theme.colors.text}
          />
        </motion.svg>
      ))}
      <motion.div
        className="background"
        initial={variants.transparent}
        variants={variants}
        animate={variants.opaque}
      ></motion.div>
    </IntroWrapper>
  )
}

export default Intro
