import React, { ReactNode, useContext, useState } from 'react'

const MouseContext = React.createContext({
  cursorVariant: 'default',
  cursorLabel: '',
  cursorChangeHandler: (cursorVariant: string, cursorLabel: string) => {},
})

export const useMouseContext = () => useContext(MouseContext)

interface IMouseProvider {
  children: ReactNode
}

export function MouseContextProvider({ children }: IMouseProvider) {
  const [cursorVariant, setCursorVariant] = useState('default')
  const [cursorLabel, setCursorLable] = useState('')

  const cursorChangeHandler = (cursorVariant: string, cursorLabel: string) => {
    setCursorVariant(cursorVariant)
    setCursorLable(cursorLabel)
  }

  return (
    <MouseContext.Provider
      value={{
        cursorVariant: cursorVariant,
        cursorLabel: cursorLabel,
        cursorChangeHandler: cursorChangeHandler,
      }}
    >
      {children}
    </MouseContext.Provider>
  )
}
