import React, { useEffect, useState } from 'react'
import { ContentContainer } from '../About/styles'
import { Coordinates, IImage, IProject } from '../../types'
import ImageHandler from '../ImageHandler'
import { InnerLine, ProjectLine } from './styles'

import 'keen-slider/keen-slider.min.css'
import { motion } from 'framer-motion'

import GallerySlider from './GallerySlider'
import { Helmet } from 'react-helmet-async'

interface IWorkProps {
  projects: IProject[]
}

const Work = ({ projects }: IWorkProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.55 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>A++ - Selected Work</title>
        <meta
          name="title"
          content="A++ - Selected Work"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Browse through our recently selected work projects."
          data-react-helmet="true"
        />
      </Helmet>
      <ContentContainer>
        <div className="text-flex">
          <h1 className="text-title">Projects</h1>
          <div className="text text-paragraphs">
            <div className="projects-list">
              {projects.map((project: IProject, index: number) => {
                const gallery =
                  project.media &&
                  project.media.length > 0 &&
                  project.media[0].gallery
                return (
                  <ProjectLine key={index}>
                    <h3>{project.title}</h3>
                    <InnerLine>
                      {gallery && (
                        <GallerySlider
                          images={gallery}
                          title={project.title}
                          projectUrl={project.urlLink}
                          sliderNumber={index}
                        />
                      )}

                      <div className="description">
                        <p>{project.description}</p>
                      </div>
                    </InnerLine>
                  </ProjectLine>
                )
              })}
            </div>
          </div>
        </div>
      </ContentContainer>
    </motion.div>
  )
}

export default Work
