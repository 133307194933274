import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ArticleContainer = styled(motion.div)`
  position: absolute;
  width: calc(100% - 64px);
  margin: 0 32px;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.fadedBackground};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 32px;

  h1 {
    /* font-size: 80px; */
    line-height: 0.85;
  }
`
