import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Button } from '../Header/styles'

export const ContentContainer = styled(motion.section)`
  position: relative;
  padding: 32px;
  /* background-color: ${(props) => props.theme.colors.fadedBackground}; */
  border-top: 0;

  h1 {
    position: sticky;
    top: 160px;
    font-family: Synthes Neue;
    font-size: 52px;
    line-height: 1.1;
    color: ${(props) => props.theme.colors.text};
    padding-bottom: 0.25em;
    text-transform: uppercase;
    /* border-bottom: 1px dashed ${(props) => props.theme.colors.text}; */

    &.work-title {
      margin-bottom: 0.75em;
      border-bottom: 0;
    }
  }

  .text-flex {
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr;
    grid-gap: 0 4px;

    .img {
      position: relative;
      width: 100%;
      aspect-ratio: 1;
      border: 1px solid ${(props) => props.theme.colors.text};
      grid-row: 1/3;

      &.grid-img {
        border: 0;
      }

      canvas {
        width: 100%;
        height: 100%;
      }
    }
    .header-column {
      margin-top: 0;
      grid-row: 2/3;
      grid-column: 2/4;
      display: flex;
      align-items: flex-end;
    }

    .text-paragraphs {
      grid-row: 1/4;
      grid-column: 2/4;
    }
  }

  h2 {
    font-family: Euclid Regular;
    font-size: 40px;
    color: ${(props) => props.theme.colors.text};
    margin-bottom: 0.25em;
  }

  .services-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid ${(props) => props.theme.colors.text};
    border-radius: 8px;
    margin-top: 24px;

    .column {
      .header {
        border-bottom: 1px solid ${(props) => props.theme.colors.text};
        border-right: 1px solid ${(props) => props.theme.colors.text};
        padding: 8px 16px;
      }

      h2 {
        font-size: 20px;
        margin-bottom: 0;
      }

      .services {
        padding: 16px;
        border-right: 1px solid ${(props) => props.theme.colors.text};

        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            margin: 0;
            padding: 0;
            color: ${(props) => props.theme.colors.text};
            font-family: Euclid Light;
            font-size: 18px;
            font-weight: 100;
            line-height: 1.4;
          }
        }
      }

      &:nth-child(2) {
        .header {
          border-right: 0;
        }

        .services {
          border-right: 0;
        }
      }
    }
  }

  .text {
    p {
      font-size: 24px;
      font-family: Euclid Light;
      line-height: 1.3;
      color: ${(props) => props.theme.colors.text};
      margin-bottom: 1em;
    }
  }

  .response {
    min-height: 200px;
    margin-top: 1em;
  }

  .email {
    position: relative;
    border-radius: 6px;
    transition: background 0.3s ease;

    h2 {
      font-size: 24px;
      transition: color 0.3s ease;
      margin-bottom: 0;
    }
  }

  .email-copied {
    position: absolute;
    height: 100%;
    left: 0;
    top: 48px;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.fadedBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 14px;
    color: ${(props) => props.theme.colors.text};
    font-family: Euclid Light;
    font-size: 16px;
  }

  @media screen and (max-width: 1000px) {
    min-height: calc(100vh - 100px);
    padding: 16px;

    h1 {
      top: 96px;
      font-size: 40px;
      padding-bottom: 16px;

      &.work-title {
        font-size: 40px;
        margin-bottom: 0.5em;
      }
    }

    .text {
      text-align: left;
      p {
        font-size: 20px;
      }
      button {
        text-align: left;
      }
    }

    button.email {
      text-align: left;
      h2 {
        font-size: 16px;
      }
    }

    .services {
      ul {
        margin-bottom: 8px;
        margin-top: 4px;
        padding: 0;
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          color: ${(props) => props.theme.colors.text};
          font-family: Euclid Light;
          font-size: 14px;
          font-weight: 100;
          line-height: 1.4;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    h1 {
      position: relative;
      top: unset;
    }

    .text-flex {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;

      .img {
        width: 100%;
      }

      .header-column {
        width: 100%;
      }

      .text-paragraphs {
        margin-top: 8px;
        width: 100%;
        grid-area: auto;
      }
    }
  }

  @media screen and (max-width: 500px) {
    min-height: calc(100vh - 64px);
    padding: 12px;
    border-radius: 6px;

    .text-flex {
      grid-gap: 8px;
    }

    .text {
      p {
        font-size: 18px;
      }
    }

    h1 {
      font-size: 40px;
      letter-spacing: -0.03em;
      padding-bottom: 12px;
      margin-top: 0.3em;

      &.work-title {
        font-size: 32px;
        margin-bottom: 0.5em;
      }
    }

    .email {
      margin-top: 0;
      padding: 6px 0;

      h2 {
        font-size: 16px;
        transition: color 0.3s ease;
        margin-bottom: 0;
      }

      .email-copied {
        padding: 8px 12px;
        left: 0;
        right: unset;
        top: 120%;
        border-radius: 4px;
      }
    }

    .services-wrapper {
      margin-top: 20px;
      grid-template-columns: 1fr;

      .column {
        .header {
          padding: 6px 8px;
          h2 {
            font-size: 14px;
          }
        }

        .services {
          padding: 8px 8px 12px;
          ul {
            li {
              font-size: 14px;
            }
          }
        }
        &:first-child {
          .header {
            border-top: 0;
            border-right: 0;
          }
          .services {
            border-right: 0;
          }
        }
        &:last-child {
          .header {
            border-top: 1px solid ${(props) => props.theme.colors.text};
          }
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    h1 {
      font-size: 28px;
      letter-spacing: -0.03em;
      margin-top: 0.3em;
    }
  }
`
