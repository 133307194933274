import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IArticle } from '../../types'
import client from '../../client'
import { PortableText } from '@portabletext/react'
import { RichContent } from './RichContent'
import { motion } from 'framer-motion'
import { ArticleContainer } from './styles'
import { bezier, bezierTwo, cubicEaseTwo } from '../../helpers/animationConstants'

const Article = () => {
  const { slug } = useParams()
  const [article, setArticle] = useState<IArticle | undefined>(undefined)
  
  useEffect(() => {
    async function fetchData() {
      try {
        const articleData = await client.fetch(
          `*[_type=='article' && slug.current == "${slug}"] {
                 ...
              } | order(publicationDate desc)`
        )

        setArticle(articleData[0])
      } catch (error) {
        console.error('Error fetching article data:', error)
      }
    }
    fetchData()
  }, [slug])

  if (!article) {
    return <p>Loading...</p>
  }

  return (
    <ArticleContainer
      // key={slug}
      className="article-template"
      initial={{ top: '100%', scaleX: 0.8 }}
      animate={{
        top: '0%',
        scaleX: 1,
        transition: { duration: 0.8, delay: 0.2, ease: bezierTwo },
      }}
      exit={{ opacity: 0, filter: 'blur(10px)', transition: { duration: 0.4 } }}
    >
      <h1>{article.title}</h1>
      <PortableText value={article.content} components={RichContent} />
    </ArticleContainer>
  )
}

export default Article
