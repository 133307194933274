import { createGlobalStyle } from 'styled-components'

import EuclidSemiboldWoff from './EuclidCircularA-Bold-WebS.woff'
import EuclidSemiboldWoff2 from './EuclidCircularA-Semibold-WebS.woff2'
import EuclidRegularWoff from './EuclidCircularA-Regular-WebS.woff'
import EuclidRegularWoff2 from './EuclidCircularA-Regular-WebS.woff2'
import EuclidBoldWoff from './EuclidCircularA-Bold-WebS.woff'
import EuclidBoldWoff2 from './EuclidCircularA-Bold-WebS.woff2'
import EuclidLightWoff from './EuclidCircularA-Light-WebS.woff'
import EuclidLightWoff2 from './EuclidCircularA-Light-WebS.woff2'
import SynthesNeueWoff from './SynthesNeueExpanded-Black.woff'
import SynthesNeueWoff2 from './SynthesNeueExpanded-Black.woff2'


export const GlobalFonts = createGlobalStyle`
 @font-face {
        font-family: 'Euclid Semibold';
        src: local('Euclid Semibold'), local('EuclidSemibold'),
        url(${EuclidSemiboldWoff2}) format('woff2'),
        url(${EuclidSemiboldWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-kerning: normal;
        text-rendering: optimizeLegibility; 
        letter-spacing: normal;
        -webkit-font-smoothing: antialiased;
        font-synthesis: none;
    }

    @font-face {
        font-family: 'Euclid Regular';
        src: local('Euclid Regular'), local('EuclidRegular'),
        url(${EuclidRegularWoff2}) format('woff2'),
        url(${EuclidRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-kerning: normal;
        text-rendering: optimizeLegibility; 
        letter-spacing: normal;
        -webkit-font-smoothing: antialiased;
        font-synthesis: none;
    }


    @font-face {
        font-family: 'Euclid Light';
        src: local('Euclid Light'), local('EuclidLight'),
        url(${EuclidLightWoff2}) format('woff2'),
        url(${EuclidLightWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-kerning: normal;
        text-rendering: optimizeLegibility; 
        letter-spacing: normal;
        -webkit-font-smoothing: antialiased;
        font-synthesis: none;
    }


    @font-face {
        font-family: 'Euclid Bold';
        src: local('Euclid Bold'), local('EuclidBold'),
        url(${EuclidBoldWoff2}) format('woff2'),
        url(${EuclidBoldWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-kerning: normal;
        text-rendering: optimizeLegibility; 
        letter-spacing: normal;
        -webkit-font-smoothing: antialiased;
        font-synthesis: none;
    }

    @font-face {
        font-family: 'Synthes Neue';
        src: local('Synthes Neue'), local('EuclidBold'),
        url(${SynthesNeueWoff2}) format('woff2'),
        url(${SynthesNeueWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-kerning: normal;
        text-rendering: optimizeLegibility; 
        letter-spacing: normal;
        -webkit-font-smoothing: antialiased;
        font-synthesis: none;
    }

`
