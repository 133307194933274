import { createClient } from '@sanity/client'

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_ID,
  dataset: 'production',
  apiVersion: '2022-03-25',
  useCdn: true,
})

export default client
