import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Button } from '../Header/styles'

export const IntroWrapper = styled(motion.section)`
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 32px;
  display: grid;
  align-items: flex-end;
  overflow: hidden;

  .info {
    position: fixed;
    top: 0;
    left: 0;
    padding: 32px;
    width: 100%;
    color: ${(props) => props.theme.colors.text};
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-family: Euclid Light, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      padding-bottom: 0;
    }

    .city-display {
      position: relative;
      width: 132px;
      height: 56px;
      border: 1px solid ${(props) => props.theme.colors.text};
      border-radius: 6px;
      padding: 8px 12px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        position: absolute;
        font-family: Synthes Neue, sans-serif;
        font-size: 32px;
        padding-bottom: 0;

        span {
          display: inline-block;
        }
      }
    }

    p {
      margin-bottom: 0;
    }

    ${Button} {
      p {
        font-family: Euclid Light;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
  }

  &.interactive {
    pointer-events: auto;
  }

  &.non-interactive {
    pointer-events: none;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: ${(props) => props.theme.colors.fadedBackground};
  }

  svg {
    position: relative;
    grid-area: 1/1;
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 1000px) {
    padding: 16px;

    .info {
      padding: 16px;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 16px;
    height: 90vh;
    top: -100px;

    .info {
      padding: 16px;

      .city-display {
        width: 100px;
        height: 48px;

        h2 {
          font-size: 24px;
        }
      }
    }
  }
`
