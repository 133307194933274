import { useEffect, useState } from 'react'

export default function useMousePosition() {
  const [mousePosition, setMousePosition] = useState({ xPos: 0, yPos: 0 })

  useEffect(() => {
    const mouseMoveHandler = (event: MouseEvent) => {
      const { clientX, clientY } = event
      setMousePosition({ xPos: clientX, yPos: clientY })
    }
    document.addEventListener('pointermove', mouseMoveHandler)
    // document.addEventListener('mousedown', mouseMoveHandler)
    document.addEventListener('dragover', mouseMoveHandler)

    return () => {
      document.removeEventListener('pointermove', mouseMoveHandler)
      // document.addEventListener('mousedown', mouseMoveHandler)
      document.addEventListener('dragover', mouseMoveHandler)
    }
  }, [])

  return mousePosition
}
