import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Button } from '../Header/styles'

export const ProjectLine = styled(motion.div)`
  position: relative;
  padding: 16px;
  margin-bottom: 1em;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 8px;
  /* border-bottom: 1px dashed ${(props) => props.theme.colors.text}; */

  @keyframes blink {
    0% {
      background-color: ${(props) => props.theme.colors.background};
    }

    30% {
      background-color: ${(props) => props.theme.colors.background};
    }

    50% {
      background-color: ${(props) => props.theme.colors.background};
    }

    55% {
      background-color: #09ff7a;
    }

    60% {
      background-color: ${(props) => props.theme.colors.background};
    }

    65% {
      background-color: #09ff7a;
    }

    70% {
      background-color: ${(props) => props.theme.colors.background};
    }

    100% {
      background-color: ${(props) => props.theme.colors.background};
    }
  }

  h3 {
    font-family: Synthes Neue;
    font-size: 24px;
    color: ${(props) => props.theme.colors.text};
    text-transform: uppercase;
    width: 75%;
  }

  ${Button} {
    width: 68px;
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    background-color: ${(props) => props.theme.colors.text};
    padding: 6px 8px 5px 10px;
    border-radius: 3px;

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    p {
      margin: 0;
      color: ${(props) => props.theme.colors.background};
      line-height: 1.1;
      font-size: 12px;
    }

    .blink {
      width: 8px;
      height: 8px;
      background-color: ${(props) => props.theme.colors.background};
      border-radius: 50%;
      animation: blink 3s infinite alternate;
    }
  }

  @media only screen and (max-width: 1000px) {
    padding-top: 1em;
    border-radius: 3px;
    h3 {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 500px) {
    padding-top: 1em;

    h3 {
      letter-spacing: -0.01em;
      width: 100%;
    }
  }
`

export const InnerLine = styled(motion.div)`
  padding: 8px 0;
  display: grid;
  grid-template-columns: 1fr;

  .button-row {
    position: absolute;
    top: 1em;
    display: flex;
    right: 1em;
    gap: 6px;

    ${Button} {
      position: relative;
      width: 74px;
      background-color: ${(props) => props.theme.colors.fadedBackground};
      border: 1px solid ${(props) => props.theme.colors.text};
      transition: opacity 0.2s ease;
      p {
        color: ${(props) => props.theme.colors.text};
        margin-bottom: 0;
      }

      &:last-child {
        background-color: ${(props) => props.theme.colors.text};
        p {
          color: ${(props) => props.theme.colors.background};
        }
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    &.bottom-left {
      bottom: 32px;
      left: 32px;
      right: unset;
      top: unset;

      ${Button} {
        &:last-child {
          background-color: ${(props) => props.theme.colors.fadedBackground};

          p {
            color: ${(props) => props.theme.colors.text};
          }
        }
      }
    }
  }

  .description {
    padding-left: 0;
    margin-top: 1em;
    p {
      font-family: Euclid Light;
      font-size: 16px;
      color: ${(props) => props.theme.colors.text};
    }
  }

  @media only screen and (max-width: 1000px) {
    padding-top: 32px;
    grid-template-columns: 1fr;
    padding-bottom: 0;
    .description {
      padding-left: unset;
      padding: 16px 0 0;
      margin-top: 0;
      margin-bottom: 0.75em;
    }
  }

  @media only screen and (max-width: 900px) {
    padding-top: 8px;
    .button-row {
      position: relative;
      top: unset;
      left: 0;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: 1em;
      grid-row: 2;

      ${Button} {
        width: 100%;
        padding: 10px 15px;
        p {
          margin-bottom: 0;
        }
      }

      &.bottom-left {
        width: calc(100% - 32px);
        position: absolute;
        bottom: 16px;
        left: 16px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .button-row {
      /* flex-direction: column; */
      gap: 4px;

      &.bottom-left {
        flex-direction: row;
        bottom: 16px;
        left: 16px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .button-row {
      ${Button} {
        p {
          font-size: 12px;
        }
      }
    }
  }
`

export const Gallery = styled(motion.div)`
  z-index: 1;
  display: flex;
  opacity: 1;
  transition: opacity 0.7s ease-in-out;

  &.hidden {
    opacity: 0;
  }

  .keen-slider__slide {
    user-select: none;
    /* pointer-events: none; */
  }
`
export const ShadowGallery = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  z-index: 99;
  left: 0;

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    left: 0;
    transition: backdrop-filter 0.5s 1s ease;
    &.expanded {
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
    }

    &.collapsed {
      backdrop-filter: blur(0px);
      -webkit-backdrop-filter: blur(0px);
    }
  }

  .slider-frame {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 24px;
    pointer-events: none;

    .border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid ${(props) => props.theme.colors.text};
      border-radius: 8px;
      z-index: 1;
      pointer-events: none;
    }

    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.hidden {
    pointer-events: none;
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  .button-container {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;

    ${Button} {
      border: 1px solid ${(props) => props.theme.colors.text} !important;
      background-color: ${(props) =>
        props.theme.colors.fadedBackground} !important;
      p {
        color: ${(props) => props.theme.colors.text} !important;
      }
    }
  }

  .slider-visible {
    pointer-events: auto;
  }

  .slider-hidden {
    pointer-events: none;
  }

  @media only screen and (max-width: 900px) {
    .slider-frame {
      padding: 16px;
    }
    &.visible {
      background-color: ${(props) => props.theme.colors.background};
    }
    padding: 16px;

    .button-container {
      top: 16px;
      right: 16px;
    }
  }
`

export const ShadowImage = styled(motion.div)`
  z-index: -1;

  .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
`
